<template>
  <div :class="['document', imgData.length > 1 ? 'content' : '']">
    <el-dialog :show-close="false" title="查看文件" :visible.sync="dialogVisible" width="1400px">
      <div class="document-main">
        <ul class="clearfix">
          <li class="document-main-li" v-for="(item, index) in paginationArr" :key="index">
            <a :href="item.url" target="_blank">
              <img class="mouseStyle" :src="item.formatPicture" />
            </a>
            <p :title="item.name" class="textOverflow" style="width:295px">{{ item.name }}</p>
          </li>
        </ul>
      </div>
      <template #footer>
        <div class="document-footer">
          <el-button @click="dialogVisible = false" size="mini" type="primary">返回</el-button>
          <el-pagination @current-change="pageChange" :page-size="8" background layout="prev, pager, next, jumper" :total="total"> </el-pagination>
        </div>
      </template>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      pageNum: 1,
      imgData: [],
      paginationArr: [],
      total: 0,
      dialogVisible: false
    };
  },
  watch: {
    dialogVisible: function(val) {
      if (val) this.$set(this, 'paginationArr', this.imgData[this.pageNum - 1]);
    }
  },
  methods: {
    pageChange(val) {
      this.pageNum = val;
      this.$set(this, 'paginationArr', this.imgData[this.pageNum - 1]);
    }
  }
};
</script>
<style lang="less" scoped>
.document {
  & /deep/ .el-dialog__header {
    height: 45px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #eff6ff;
  }
  & /deep/ .el-dialog__body {
    padding-bottom: 0;
  }
  .content {
    & /deep/ .el-dialog__footer {
      padding-top: 0;
    }
  }
  &-main {
    padding: 15px 20px;
    &-li {
      float: left;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      width: 300px;
      height: 240px;
      margin: 0 0 20px 40px;
      &:nth-child(1) {
        margin: 0;
      }
      &:nth-child(5) {
        margin: 0;
      }
    }
  }
  &-footer {
    display: flex;
    padding: 0 20px;
    justify-content: space-between;
  }
}
</style>
