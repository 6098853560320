var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: ["document", _vm.imgData.length > 1 ? "content" : ""] },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            "show-close": false,
            title: "查看文件",
            visible: _vm.dialogVisible,
            width: "1400px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticClass: "document-footer" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { size: "mini", type: "primary" },
                          on: {
                            click: function ($event) {
                              _vm.dialogVisible = false
                            },
                          },
                        },
                        [_vm._v("返回")]
                      ),
                      _c("el-pagination", {
                        attrs: {
                          "page-size": 8,
                          background: "",
                          layout: "prev, pager, next, jumper",
                          total: _vm.total,
                        },
                        on: { "current-change": _vm.pageChange },
                      }),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("div", { staticClass: "document-main" }, [
            _c(
              "ul",
              { staticClass: "clearfix" },
              _vm._l(_vm.paginationArr, function (item, index) {
                return _c(
                  "li",
                  { key: index, staticClass: "document-main-li" },
                  [
                    _c("a", { attrs: { href: item.url, target: "_blank" } }, [
                      _c("img", {
                        staticClass: "mouseStyle",
                        attrs: { src: item.formatPicture },
                      }),
                    ]),
                    _c(
                      "p",
                      {
                        staticClass: "textOverflow",
                        staticStyle: { width: "295px" },
                        attrs: { title: item.name },
                      },
                      [_vm._v(_vm._s(item.name))]
                    ),
                  ]
                )
              }),
              0
            ),
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }